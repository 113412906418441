import { Layout, LayoutItem, Text } from '@audi/audi-ui-react';
import { UeElement } from '@oneaudi/falcon-tools';
import { renderTextWithFootnotesReferencesV2 } from '@oneaudi/feature-app-utils';
import React from 'react';
import styled from 'styled-components';
import { WltpObject, WltpProps } from '../../FeatureApp';

export interface ConsumptionAndEmission {
  readonly consumption: string;
  readonly emission: string;
}

export interface LegalDataProps {
  readonly consumptionsAndEmissions: WltpProps[];
  readonly additionalText: string;
  readonly isTextOnly?: boolean;
  readonly propertyWLTP?: string;
  readonly propertyAdditionalText?: string;
}

const StyledBlockSpan = styled.span`
  display: block;
  margin-bottom: 3px;
`;

const StyledLayoutItem = styled.div`
  sup {
    position: initial;
    line-height: initial;
  }
  margin-inline: 0;
`;

const WltpSpan = styled.span``;

const WltpText = React.memo(
  ({
    formattedConsumption,
    formattedEmission,
    formattedCo2Class,
    formattedDischargedConsumption,
    formattedDischargedCo2Class,
  }: WltpObject) => (
    <StyledBlockSpan>
      <WltpSpan data-testid="consumption">{formattedConsumption}</WltpSpan>
      <WltpSpan data-testid="emission">; {formattedEmission}</WltpSpan>
      {formattedCo2Class && <WltpSpan data-testid="co2Class">; {formattedCo2Class}</WltpSpan>}
      {formattedDischargedConsumption && (
        <WltpSpan data-testid="dischargedConsumption">; {formattedDischargedConsumption}</WltpSpan>
      )}
      {formattedDischargedCo2Class && (
        <WltpSpan data-testid="dischargedCo2Class">; {formattedDischargedCo2Class}</WltpSpan>
      )}
    </StyledBlockSpan>
  ),
);

const LegalData: React.FC<LegalDataProps> = React.memo(
  ({
    consumptionsAndEmissions,
    additionalText,
    isTextOnly,
    propertyWLTP,
    propertyAdditionalText,
  }: LegalDataProps) => {
    if (consumptionsAndEmissions.length === 0 && additionalText?.length === 0) {
      return null;
    }

    return (
      <Layout>
        <StyledLayoutItem>
          <LayoutItem spaceStackStart={isTextOnly ? 'm' : 's'} data-testid="legalData">
            <UeElement type="text" property={propertyWLTP} label="Legal text">
              <Text variant="copy2">
                {consumptionsAndEmissions.map((cae) => {
                  const caEData = Object.values(cae)[0];
                  const { formattedConsumption, formattedEmission } = caEData;
                  return (
                    <WltpText key={`${formattedConsumption}${formattedEmission}`} {...caEData} />
                  );
                })}
              </Text>
            </UeElement>
            {additionalText && additionalText.length > 0 && (
              <UeElement type="text" property={propertyAdditionalText} label="Additional text">
                <Text variant="copy2">{renderTextWithFootnotesReferencesV2(additionalText)}</Text>
              </UeElement>
            )}
          </LayoutItem>
        </StyledLayoutItem>
      </Layout>
    );
  },
);

export default LegalData;
