import { Text as TextWrapper } from '@audi/audi-ui-react';
import { UeElement } from '@oneaudi/falcon-tools';
import React, { memo } from 'react';
import styled from 'styled-components';
import { convertRichText } from './textUtils';

const StyledText = styled.span`
  sup {
    position: initial;
    line-height: initial;
  }

  & p {
    margin: 0;
    padding: 0;

    & + p {
      margin-block-start: var(${(props) => props.theme.responsive.spacing.m});
    }
  }
`;

export interface TextProps {
  readonly text: string;
  readonly property: string;
}

export default memo(function Text({ text, property }: TextProps) {
  return (
    <UeElement type="text" property={property} label="Text">
      <TextWrapper as="span">
        <StyledText>{convertRichText(text)}</StyledText>
      </TextWrapper>
    </UeElement>
  );
});
