import { Logger } from '@feature-hub/logger';
import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import { VueFormatterServiceInterfaceV1 } from '@oneaudi/vue-formatter-service';
import { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';
import React, { createContext, PropsWithChildren, useContext, useMemo } from 'react';

interface ContextState {
  readonly loggerService?: Logger;
  readonly vueFormatterService: VueFormatterServiceInterfaceV1;
  readonly localeService: LocaleServiceV1;
  readonly trackingService?: TrackingServiceV2;
}

export const Context = createContext<ContextState>({} as ContextState);

export interface ContextProviderProps {
  readonly localeService: LocaleServiceV1;
  readonly vueFormatterService: VueFormatterServiceInterfaceV1;
  readonly logger?: Logger;
  readonly trackingService?: TrackingServiceV2;
}

const ContextProvider = ({
  children,
  vueFormatterService,
  localeService,
  logger,
  trackingService,
}: PropsWithChildren<ContextProviderProps>) => {
  const value = useMemo(
    () => ({
      loggerService: logger,
      vueFormatterService,
      localeService,
      trackingService,
    }),
    [],
  );
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default ContextProvider;

function getContext() {
  const ctx = useContext(Context);
  if (ctx === null) {
    throw new Error(
      'Context not initialized. Did you forget to wrap the Feature App in <ContextProvider>?',
    );
  }
  return ctx;
}

export function useLogger() {
  return getContext().loggerService;
}
