import { useContext, useMemo } from 'react';
import { Context, useLogger } from './Context';
import { Content } from '../EditorContentTypes';
import { checkLinkTarget, createRelatedElementsArray } from '../utils/trackingUtils';

export interface TrackingManager {
  ready(version: string, numberOfImages: number): void;
  impression(content: Content): void;
  click(targetURL: string, label: string, moduleIndex: number, linkIndex: number): void;
}

export interface RelatedElements {
  elementName: string;
  elementValue: string;
}

export interface TrackingEvent {
  event: {
    eventInfo: {
      eventAction: string;
      eventName: string;
    };
    attributes: Record<string, unknown>;
  };
}

const createTrackingPayload = (
  action: string,
  name: string,
  attributes: Record<string, unknown>,
): TrackingEvent => {
  const { host, pathname } = document.location;
  const currentURL = `${host}${pathname}`;
  const defaultAttributes = {
    componentName: 'modular-editorial-feature-app',
    currentURL,
  };
  return {
    event: {
      eventInfo: {
        eventAction: action,
        eventName: name,
      },
      attributes: {
        ...defaultAttributes,
        ...attributes,
      },
    },
  };
};

const createComponentPayload = (version: string, numberOfImages: number) => {
  return {
    componentInfo: {
      componentID: __FEATURE_APP_NAME__,
    },
    attributes: {
      implementer: 2,
      version,
      numberOfImages,
    },
  };
};

export const useTrackingManager = () => {
  const { trackingService } = useContext(Context);
  const logger = useLogger();

  return useMemo((): TrackingManager => {
    const track = (...args: Parameters<typeof createTrackingPayload>): void => {
      if (trackingService) {
        const payload = createTrackingPayload(...args);
        if (logger) {
          logger.debug('Sending tracking event: ', payload);
        }
        trackingService.track(payload);
      }
    };
    const trackWithComponent = (
      version: string,
      numberOfImages: number,
      ...args: Parameters<typeof createTrackingPayload>
    ): void => {
      if (trackingService) {
        const payload = createTrackingPayload(...args);
        const componentPayload = createComponentPayload(version, numberOfImages);

        if (logger) {
          logger.debug('Sending tracking event: ', {
            ...payload,
            additionalObjects: componentPayload,
          });
        }
        trackingService.track({
          ...payload,
          componentUpdate: componentPayload,
        });
      }
    };
    return {
      ready(version, numberOfImages) {
        trackWithComponent(
          version,
          numberOfImages,
          'feature_app_ready',
          'modular editorial feature app - feature app ready',
          {
            label: '',
            targetURL: '',
            clickID: '',
            elementName: '',
            value: '',
            pos: '',
          },
        );
      },
      impression(content: Content) {
        const relatedElements = createRelatedElementsArray(content);
        track('impression', 'modular editorial feature app - impression', {
          label: '',
          targetURL: '',
          clickID: '',
          elementName: '',
          value: '',
          pos: '',
          relatedElements,
        });
      },
      click(targetURL: string, label: string, moduleIndex: number, linkIndex: number) {
        const relatedElements = [
          {
            elementName: 'link',
            elementValue: `${moduleIndex}-${linkIndex}-${label}`,
          },
        ];
        const completeTargetURL = (url: string) => {
          const { host } = document.location;
          if (url.startsWith('/')) {
            return `${host}${url}`;
          }
          return url;
        };
        track(checkLinkTarget(targetURL) || '', 'modular editorial feature app - click on link', {
          label,
          targetURL: completeTargetURL(targetURL),
          clickID: '',
          elementName: 'text link',
          value: '',
          pos: '',
          relatedElements,
        });
      },
    };
  }, [trackingService]);
};
