import { UeElement } from '@oneaudi/falcon-tools';
import React from 'react';
import styled from 'styled-components';
import { ImageProps } from '../../EditorContentTypes';

const StyledImage = styled.img`
  display: block;
  width: 100%;
  height: auto;
`;

const Image: React.FC<ImageProps> = React.memo(
  ({ imageMobile, imageDesktop, alternativeText, propertyImageDesktop }: ImageProps) => (
    <UeElement type="media" property={propertyImageDesktop} label="Image">
      <picture>
        <source srcSet={imageDesktop.assetValue} media="(min-width: 768px)" />
        <StyledImage src={imageMobile.assetValue} alt={alternativeText} />
      </picture>
    </UeElement>
  ),
);

export default Image;
