import { Button, ButtonGroup } from '@audi/audi-ui-react';
import React, { useCallback } from 'react';
import { UeElement } from '@oneaudi/falcon-tools';
import { LinkProps } from '../../EditorContentTypes';
import { useTrackingManager } from '../../context/useTrackingManager';

export interface CTAsProps {
  readonly links: LinkProps[];
  readonly moduleIndex: number;
  readonly property0Text?: string;
  readonly property1Text?: string;
}

const CTAs: React.FC<CTAsProps> = ({
  links,
  moduleIndex,
  property0Text,
  property1Text,
}: CTAsProps) => {
  if (links.length === 0) {
    return null;
  }
  const trackingManager = useTrackingManager();
  const linkTracking = useCallback((href: string, text: string, linkIndex: number) => {
    trackingManager.click(href, text, moduleIndex, linkIndex);
  }, []);
  return (
    <ButtonGroup variant="text-buttons" spaceStackStart="l" data-testid="ctas">
      {links.map(({ href, text }, i) => {
        return (
          <Button
            variant="text"
            href={href}
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            data-testid="cta"
            onClick={() => {
              if (linkTracking) {
                linkTracking(href, text, i + 1);
              }
            }}
          >
            <UeElement type="text" property={i === 0 ? property0Text : property1Text} label="Text">
              {text}
            </UeElement>
          </Button>
        );
      })}
    </ButtonGroup>
  );
};

export default CTAs;
