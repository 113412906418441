import { Asset } from '@oneaudi/falcon-tools';
import {
  Content,
  HeadlessContent,
  HeadlessModule,
  HeadlineHierarchy,
  Layout,
  LinkProps,
  Module,
} from '../EditorContentTypes';

export const mapHeadlessContent = (content: Content | HeadlessContent) => {
  if (content && '__type' in content && content.__type === 'aem-headless') {
    const normalisedContent: Content = {
      topModule: {
        additionalModule: false,
        moduleContent: content.fields.topModule_moduleContent,
        layout: content.fields.topModule_layout as Layout,
        headline: {
          text: content.fields.topModule_headline_text,
          hierarchy: content.fields.topModule_headline_hierarchy as HeadlineHierarchy,
        },
        text: content.fields.topModule_text,
        articleWidth: content.fields.topModule_articleWidth,
        image: {
          imageDesktop: {
            assetValue: content.fields.topModule_image_imageDesktop
              ? (content.fields.topModule_image_imageDesktop as Asset)?.path
              : '',
          },
          imageMobile: {
            assetValue: content.fields.topModule_image_imageMobile
              ? (content.fields.topModule_image_imageMobile as Asset)?.path
              : '',
          },
          alternativeText: content.fields.topModule_image_alternativeText,
        },
        legalData: {
          additionalText: content.fields.topModule_legalData_additionalText,
          wltpKeys: content.fields.topModule_legalData_wltpKeys,
        },
        links: mapLinks(content),
      },
      additionalModules: mapModules(content.fields.additionalModules),
      lightDarkMode: content.fields.lightDarkMode,
    };
    return normalisedContent;
  }
  return content as Content;
};
export const mapModules = (modules: HeadlessModule[]): Module[] => {
  const mappedModules: Module[] = [];
  modules.forEach((module) => {
    mappedModules.push({
      moduleContent: module.fields.moduleContent,
      layout: module.fields.layout as Layout,
      headline: {
        text: module.fields.headline_text,
        hierarchy: module.fields.headline_hierarchy as HeadlineHierarchy,
      },
      text: module.fields.text,
      articleWidth: module.fields.articleWidth,
      image: {
        imageDesktop: {
          assetValue: module.fields.image_imageDesktop
            ? (module.fields.image_imageDesktop as Asset).path
            : '',
        },
        imageMobile: {
          assetValue: module.fields.image_imageMobile
            ? (module.fields.image_imageMobile as Asset).path
            : '',
        },
        alternativeText: module.fields.image_alternativeText,
      },
      legalData: {
        additionalText: module.fields.legalData_additionalText,
        wltpKeys: module.fields.legalData_wltpKeys,
      },
      links: mapModuleLinks(module),
      additionalModule: true,
    });
  });
  return mappedModules;
};

export const mapLinks = (content: HeadlessContent) => {
  const links: LinkProps[] = [];
  if (content.fields.topModule_links_0_text && content.fields.topModule_links_0_href) {
    links.push({
      href: content.fields.topModule_links_0_href,
      text: content.fields.topModule_links_0_text,
    });
  }
  if (content.fields.topModule_links_1_href && content.fields.topModule_links_1_text) {
    links.push({
      href: content.fields.topModule_links_1_href,
      text: content.fields.topModule_links_1_text,
    });
  }
  return links;
};

export const mapModuleLinks = (content: HeadlessModule) => {
  const links: LinkProps[] = [];
  if (content.fields.links_0_href && content.fields.links_0_text) {
    links.push({
      href: content.fields.links_0_href,
      text: content.fields.links_0_text,
    });
  }
  if (content.fields.links_1_href && content.fields.links_1_text) {
    links.push({
      href: content.fields.links_1_href,
      text: content.fields.links_1_text,
    });
  }
  return links;
};
