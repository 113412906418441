/**
 * This is the starting point of your application.
 * oneAudi OS and Feature Hub Apps will use this file to bootstrap the app.
 */
import { useContent, useInViewEffect } from '@oneaudi/feature-app-utils';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { UeContainer, UeReference } from '@oneaudi/falcon-tools';
import { Content, HeadlessContent, Module } from './EditorContentTypes';
import { AsyncStateHolder } from './FeatureHubAppDefinition';
import Block, { BackgroundWrapper } from './components/block/Block';
import { Context, useLogger } from './context/Context';
import { useTrackingManager } from './context/useTrackingManager';
import { createInitialState } from './utils/stateUtils';
import { countNumberOfImagesInAllModules } from './utils/trackingUtils';
import { mapHeadlessContent } from './utils/mapHeadlessContent';

export interface FeatureAppProps {
  content: Content;
  consumptionsAndEmissions: WltpProps[];
}

export interface WltpProps {
  [key: string]: WltpObject;
}

export interface WltpObject {
  formattedConsumption: string | Iterable<ReactNode>;
  formattedEmission: string | Iterable<ReactNode>;
  formattedCo2Class?: string | Iterable<ReactNode>;
  formattedDischargedCo2Class?: string | Iterable<ReactNode>;
  formattedDischargedConsumption?: string | Iterable<ReactNode>;
}

interface AsyncFeatureAppProps {
  readonly asyncStateHolder: AsyncStateHolder;
}

const mapLegalDataToBlock = (
  wltpKeys: string[],
  consumptionsAndEmissions: Record<string, WltpObject>[],
) => {
  return consumptionsAndEmissions.filter((wltpObj) => wltpKeys.includes(Object.keys(wltpObj)[0]));
};

const createToBlockMapper =
  (consumptionsAndEmissions: Record<string, WltpObject>[]) =>
  (
    {
      moduleContent,
      layout,
      headline,
      text,
      articleWidth,
      image,
      links,
      legalData,
      additionalModule,
    }: Module,
    index?: number,
  ) => {
    const additionalText = legalData?.additionalText;
    const additionalModuleIndex = index !== undefined ? index + 2 : undefined;
    const moduleIndexForTracking = additionalModuleIndex ?? 1;
    const consumptionAndEmissionPerBlock =
      legalData &&
      legalData.wltpKeys &&
      mapLegalDataToBlock(legalData?.wltpKeys, consumptionsAndEmissions);

    return (
      <Block
        key={index}
        moduleContent={moduleContent}
        layout={layout}
        headline={headline}
        text={text}
        articleWidth={articleWidth}
        image={image}
        links={links}
        legalData={{
          consumptionsAndEmissions: consumptionAndEmissionPerBlock || [],
          additionalText,
        }}
        additionalModule={additionalModule ?? false}
        moduleIndex={moduleIndexForTracking}
      />
    );
  };

const FeatureApp: React.FC<FeatureAppProps> = ({
  content,
  consumptionsAndEmissions,
}: FeatureAppProps) => {
  const trackingManager = useTrackingManager();

  const totalNumberOfImages = countNumberOfImagesInAllModules(content);

  if (!content || !consumptionsAndEmissions) {
    return null;
  }
  useEffect(() => {
    trackingManager.ready(__FEATURE_APP_VERSION__, totalNumberOfImages);
  }, []);

  useInViewEffect(() => {
    if (content) {
      trackingManager.impression(content);
    }
  });

  const toBlock = createToBlockMapper(consumptionsAndEmissions);

  return (
    <BackgroundWrapper>
      {toBlock(content.topModule)}
      <UeContainer propertyPath="additionalModules" label="Additional Modules">
        {content.additionalModules.map((additionalModule, i) => (
          <UeReference
            key={additionalModule.text}
            propertyPath={`additionalModules.${i}`}
            behavior="component"
          >
            {toBlock(content.additionalModules[i])}
          </UeReference>
        ))}
      </UeContainer>
    </BackgroundWrapper>
  );
};

const AsyncFeatureApp: React.FC<AsyncFeatureAppProps> = ({
  asyncStateHolder,
}: AsyncFeatureAppProps) => {
  const [state, setState] = useState<FeatureAppProps | undefined>(
    typeof asyncStateHolder === 'object' ? asyncStateHolder : undefined,
  );
  const { vueFormatterService, localeService } = useContext(Context);
  const initialContent = useContent() as Content | HeadlessContent;
  const content = mapHeadlessContent(initialContent);
  const logger = useLogger();

  useEffect(() => {
    let mounted = true;

    if (state && content) {
      createInitialState(content, vueFormatterService, localeService, logger).then(
        (initialState) => mounted && setState(initialState),
      );
    }
    return () => {
      mounted = false;
    };
  }, [initialContent]);

  useEffect(() => {
    if (typeof asyncStateHolder === 'function') {
      logger?.info('SSR did not serialize any state');
      asyncStateHolder().then(setState);
    } else {
      logger?.info('SSR serialized state', asyncStateHolder);
    }
  }, []);

  if (!state) {
    return null;
  }
  return <FeatureApp {...state} />;
};

export default AsyncFeatureApp;
