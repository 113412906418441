import { Logger } from '@feature-hub/logger';
import { getConsumptionsAndEmissionsWithIdentifiers } from '@oneaudi/feature-app-utils';
import {
  ConsumptionsAndEmissions,
  VueFormatterServiceInterfaceV1,
} from '@oneaudi/vue-formatter-service';
import { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';
import flatten from 'lodash.flatten';
import uniq from 'lodash.uniq';
import type { LocaleServiceConfig } from '@volkswagen-onehub/locale-service/dist';
import { Content } from '../EditorContentTypes';

export function getUniqueWltpKeys(content: Content) {
  return uniq([
    ...(content.topModule?.legalData?.wltpKeys || []),
    ...flatten(content.additionalModules.map(({ legalData }) => legalData?.wltpKeys || [])),
  ]);
}

export async function getConsumptionsAndEmissions(
  content: Content,
  vueFormatterService: VueFormatterServiceInterfaceV1,
  localeService: LocaleServiceV1,
  logger?: Logger,
): Promise<Record<string, ConsumptionsAndEmissions>> {
  const uniqueWltpKeys = getUniqueWltpKeys(content);
  const caeData = await getConsumptionsAndEmissionsWithIdentifiers(
    uniqueWltpKeys,
    vueFormatterService,
    localeService as LocaleServiceConfig,
    logger,
  );
  return caeData;
}
