import parse from 'html-react-parser';
import React, { ReactNode, cloneElement, FC } from 'react';
import sanitizeHtml from 'sanitize-html';
import styled from 'styled-components';
import { List, ListItem, ListProps } from '@audi/audi-ui-react';
import { renderReactNodeWithFootnotes } from '../../utils/renderReactNodeWithFootnotes';

enum AllowedTag {
  B = 'b',
  I = 'i',
  U = 'u',
  UL = 'ul',
  LI = 'li',
  BR = 'br',
  P = 'p',
  A = 'a',
  STRONG = 'strong',
}

const StyledList = styled(List as FC<ListProps>)`
  margin-block: var(${(props) => props.theme.responsive.spacing.m});
  margin-inline-start: var(${(props) => props.theme.responsive.spacing.m});
`;

const createElement = (type: string, props?: object, ...children: ReactNode[]): JSX.Element => {
  if (type === AllowedTag.BR) {
    return React.createElement(type, props);
  }
  const renderChildren = () => renderReactNodeWithFootnotes(children);
  if (type === AllowedTag.UL) {
    return React.createElement(StyledList, props, renderChildren());
  }
  if (type === AllowedTag.LI) {
    return React.createElement(ListItem, props, renderChildren());
  }
  return React.createElement(type, props, renderChildren());
};

export function convertRichText(htmlString: string) {
  const sanitizedHtmlString = sanitizeHtml(htmlString, {
    allowedTags: Object.values(AllowedTag),
  });

  return parse(sanitizedHtmlString, {
    library: {
      cloneElement,
      createElement,
      isValidElement: (element: unknown): boolean => {
        return Boolean(element);
      },
    },
  });
}
