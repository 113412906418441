import { Logger } from '@feature-hub/logger';
import {
  cleanupEmissionConsumptionMarkup,
  FootnoteReference,
  renderTextWithFootnotesReferencesV2,
} from '@oneaudi/feature-app-utils';
import {
  ConsumptionsAndEmissions,
  VueFormatterServiceInterfaceV1,
} from '@oneaudi/vue-formatter-service';
import { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';
import { createElement, ReactNode } from 'react';
import { Content } from '../EditorContentTypes';
import { FeatureAppProps, WltpObject } from '../FeatureApp';
import { getConsumptionsAndEmissions } from './getConsumptionsAndEmissions';

export const createInitialState = async (
  content: Content,
  vueFormatterService: VueFormatterServiceInterfaceV1,
  localeService: LocaleServiceV1,
  logger?: Logger,
): Promise<FeatureAppProps> => {
  const wltpData = await getConsumptionsAndEmissions(
    content,
    vueFormatterService,
    localeService,
    logger,
  );
  const consumptionsAndEmissions: Record<string, WltpObject>[] = [];
  Object.entries(wltpData).forEach(([key, value]) => {
    const {
      formattedConsumption,
      formattedEmission,
      formattedCo2Class,
      formattedDischargedConsumption,
      formattedDischargedCo2Class,
    } = value;
    if (formattedConsumption && formattedEmission) {
      consumptionsAndEmissions.push({
        [key]: {
          formattedConsumption: renderTextWithFootnotesReferencesV2(
            cleanupEmissionConsumptionMarkup(formattedConsumption),
          ),
          formattedEmission: renderTextWithFootnotesReferencesV2(
            cleanupEmissionConsumptionMarkup(formattedEmission),
          ),
          formattedCo2Class: formattedCo2Class
            ? renderTextWithFootnotesReferencesV2(
                cleanupEmissionConsumptionMarkup(formattedCo2Class),
              )
            : undefined,
          formattedDischargedConsumption: formattedDischargedConsumption
            ? renderTextWithFootnotesReferencesV2(
                cleanupEmissionConsumptionMarkup(formattedDischargedConsumption),
              )
            : undefined,
          formattedDischargedCo2Class: formattedDischargedCo2Class
            ? renderTextWithFootnotesReferencesV2(
                cleanupEmissionConsumptionMarkup(formattedDischargedCo2Class),
              )
            : undefined,
        },
      });
    }
  });

  return { content, consumptionsAndEmissions };
};
const deserializeReactNodeArray = (
  deserializedProperty?: string | (string | Record<string, unknown>)[],
): undefined | string | ReactNode[] => {
  if (!deserializedProperty || typeof deserializedProperty === 'string') {
    return deserializedProperty;
  }
  return deserializedProperty.map((serializedReactNode) => {
    if (typeof serializedReactNode === 'string') {
      return serializedReactNode;
    }
    return createElement(FootnoteReference, serializedReactNode.props as undefined);
  });
};
const deserializeReactNodeArrayInWltpData = (
  wltpData: Record<string, ConsumptionsAndEmissions>[],
) => {
  return wltpData.map((record) => {
    return Object.entries(record).map(([key, value]) => {
      return {
        [key]: {
          formattedConsumption: deserializeReactNodeArray(value.formattedConsumption),
          formattedEmission: deserializeReactNodeArray(value.formattedEmission),
        },
      };
    });
  });
};

export const deserializeState = (state: string) => {
  const props = JSON.parse(state);
  return {
    ...props,
    consumptionsAndEmissions: deserializeReactNodeArrayInWltpData(props.consumptionsAndEmissions),
  };
};
