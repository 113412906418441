import { Text } from '@audi/audi-ui-react';
import { UeElement } from '@oneaudi/falcon-tools';
import { renderTextWithFootnotesReferencesV2 } from '@oneaudi/feature-app-utils';
import React from 'react';
import { HeadlineProps } from '../../EditorContentTypes';

const Headline: React.FC<HeadlineProps> = React.memo(
  ({ text, hierarchy, property }: HeadlineProps) => {
    if (text?.length === 0) {
      return null;
    }
    return (
      <UeElement type="text" property={property} label="Headline">
        <Text as={hierarchy} variant="order2" data-testid="headline" spaceStackEnd="l">
          {renderTextWithFootnotesReferencesV2(text)}
        </Text>
      </UeElement>
    );
  },
);

export default Headline;
